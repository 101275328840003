
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import BMessage from 'buetify/lib/components/message/BMessage';
import { defineComponent } from 'vue';
import CodeView from '../../../../components/codeView/CodeView.vue';
import UsageExampleCode from '!!raw-loader!./UsageExample.vue';

export default defineComponent({
	name: 'start-documentation',
	components: {
		CodeView,
		BHorizontalDivider,
		BMessage
	},
	setup() {
		return {
			UsageExampleCode
		};
	}
});
